<template>
      <div class="inventoryReservationCreate">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'inventoryReservation', params: {} }">
          Inventory Reservation
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'inventoryReservationCreate', params: {} }">
            Create Inventory Reservation
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Create Inventory Reservation</v-card-title>
          <v-card-text>
            <inventoryReservation-form></inventoryReservation-form>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <script>
    import InventoryReservationForm from './InventoryReservationForm.vue';

    export default {
      name: 'inventoryReservation',
      components: {
        InventoryReservationForm
      },
      created() {
        this.$emit("showParent", false);
      }
    }
    </script>
    